.name {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Georgia";
  font-size: 2.75rem;
  display: block;
  width: 8rem;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
  font-weight: normal;
}

.name-split {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 40px;
  will-change: transform, opacity;
  overflow: hidden;
}

.title {
  margin-bottom: 2rem;
  margin-top: 6rem;
  will-change: opacity;
}

.intro {
  max-width: 60rem;
  padding-right: 2rem;
  display: block;
  font-size: 1rem;
  margin: 0;
  line-height: 1.85rem;
  letter-spacing: 0.25px;
  margin-top: 0.25rem;
  font-weight: normal;
}

.logo {
  margin-bottom: 1.25rem;
  max-width: 10rem;
  max-height: 11rem;
}

@media (max-width: 886px) {
  .headshot {
    opacity: 0;
    width: 5%;
  }
}

.headshot {
  margin-top: 7.87rem;
  float: right;
  max-width: 27%;
  margin-left: 2rem;
  filter: brightness(1.5);
}

strong {
  color: rgb(156, 1, 24);
  font-weight: bolder;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
}

b {
  color: rgb(145, 226, 230);
  font-weight: bolder;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
}

em {
  color: rgb(0, 114, 206);
  font-weight: bolder;
  font-style: normal;

  &:hover {
    opacity: 0.7;
    transition: 0.2s;
  }
}

@media screen and (min-width: 768px) {
  .title {
    display: flex;
  }

  .intro {
    // max-width: 24rem;
    padding-top: 3px;
  }

  .name {
    margin-bottom: 0;
  }

  .headshot {
    animation: fadeIn 2s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

// iPhone Query
@media screen and (max-width: 416px) {
  .intro{
    font-size: 75%;
  }
}

@media screen and (min-width: 1000px) {
  .headshot {
    animation: fadeIn 2s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
