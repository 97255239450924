:root {
  --bg-color: #000;
  --term-color: rgb(25, 25, 25);
}

html {
  scroll-behavior: smooth;
}
:root {
  --padding: 6rem 10%;
  --padding-lg: 3rem;

  --itemWidth: 13.25rem;
  --itemHeight: 20rem;

  @media screen and (min-width: 768px) {
    --itemWidth: 17.8rem;
    --itemHeight: 26rem;
  }
}

@import url("https://fonts.googleapis.com/css?family=Rammetto+One&display=swap");

::selection {
  background: #333;
  color: #fff;
}
::-moz-selection {
  background: #333;
  color: #fff;
}

button:focus {
  outline: none;
}

html {
  font-size: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: common-ligatures;
  line-height: 1.35;
  letter-spacing: -0.4px;
  color: #fff;
  background-color: black;
  .Social-anchor-circle {
    background-color: #ffffff;
  }
}

.bg-color {
  color: #fff;
  background: linear-gradient(-45deg, #1e1b2c, #001a05, #001231, #420102);
  background-size: 400% 400%;
  -webkit-animation: Gradient 60s ease infinite;
  -moz-animation: Gradient 60s ease infinite;
  animation: Gradient 60s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
  }

  .Social-anchor-circle {
    background-color: #ffffff;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.u-visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus {
  color: inherit;
}

a:active,
a:hover,
a:focus {
  outline: 0;
}

button {
  text-transform: none;
  cursor: pointer;
  background-color: transparent;
  margin: 0;
  border: 0;
  padding: 0;
  text-align: left;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

a,
button,
input,
select,
textarea,
label,
summary {
  touch-action: manipulation;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2 {
  font-size: 1.5rem;
  margin: 0;
}

h3 {
  font-size: inherit;
  margin: 0 0 1rem;
}

p {
  margin: 0 0 1rem;
}

h2 {
  margin: 0 0 0.5rem;
}

.root {
  overflow: hidden;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  padding: 1.5rem 1.25rem; //var(--padding);
  max-width: 53rem;
}

.section {
  margin-bottom: 2rem;
}

.sub-heading-wrapper {
  overflow: hidden;
  margin-bottom: 1.35rem;
}

.sub-heading {
  font-family: "Georgia";
  font-size: 1.8rem;
  margin-top: 4rem;
  padding-right: 1.8rem;
  font-weight: normal;
  float: left;
}

// Arrow Animation
#arrowAnim {
  display: flex;
  margin-top: 4.75rem;
  animation: fadeIn 6s;
}

.arrow {
  border: 8px solid;
  opacity: 0.3;
  border-color: white transparent transparent white;
  transform: rotate(135deg);
}

.arrowSliding {
  position: absolute;
  opacity: 0;
  -webkit-animation: slide 1.5s linear infinite;
  animation: slide 1.5s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.delay2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  20% {
    opacity: 1;
    transform: translateX(-20px);
  }
  80% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    transform: translateX(25px);
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  20% {
    opacity: 1;
    transform: translateX(-20px);
  }
  80% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    transform: translateX(25px);
  }
}

@media screen and (min-width: 768px) {
  .container {
    position: inherit;
    padding: var(--padding-lg);
    padding-top: 1.5rem;
  }

  body {
    font-size: 1.05rem;
  }
}
