.about-container {
  margin: 0 auto;
  background-color: transparent;
  animation: fadeIn ease 2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .terminal-header {
    height: 1.75rem;
    background-color: rgb(210, 205, 205);
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    overflow: overlay;
    opacity: 1;

    .header-button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 9px;
    }

    .red {
      background-color: #f96256;
      border: solid 0.5vh #f65549;

      &:hover {
        opacity: 0.7;
        transition: 0.3s;
      }
    }

    .yellow {
      background-color: #fdbc3d;
      border: solid 0.5vh #ffb524;
    }

    .green {
      background-color: #33c948;
      border: solid 0.5vh #2dbb41;
    }

    span {
      color: black;
      font-size: 1rem;
      display: table;
      margin: 0 auto;
      //margin-left: 440px;
      font-family: "Garamond", monospace;
    }

    .bash {
      display: table;
      margin: 0 auto;
      font-family: "Menlo";
    }

    @keyframes caret {
      50% {
        background-color: transparent;
      }
    }
  }

  .terminal-window {
    box-shadow: rgba(0, 0, 0, 0.2) 15px 12px 12px;
    border-radius: 0px 0px 10px 10px;
    font-family: "Menlo";
    margin-bottom: 7rem;
    padding-top: 1rem;
    opacity: 1;
    line-height: 1.75rem;
    background-color: var(--term-color);

    a {
      color: blue;
    }

    // .blinking {
    //   background: rgba(247, 247, 247, 0.65);
    //   animation: caret 1s steps(1) infinite;
    //   float: right;
    // }

    .transparent {
      display: table-cell;
      float: left;
      width: 100%;
      border: none;
      background: transparent;
      color: white;
      outline: none;
      font-family: "Menlo";
      font-size: 1.05rem;
    }

    .messageDiv {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .last-statement {
      color: #fffae3;
      margin-bottom: 0.5rem;
    }
    .message-carat{
      margin-right: 0.5rem;
    }

    .statement:last-child {
      padding-bottom: 2rem;
    }

    .statement {
      margin-bottom: 1rem;
      margin-left: 2rem;
      margin-right: 1rem;
      font-size: 1.05rem;
      letter-spacing: -1px;

      .input-statement {
        color: #5cff5c;
      }

      .return-statement {
        color: #fffae3;
        margin-bottom: 0.5rem;
        // &:before {
        //   content: ">\a0";
        // }
      }

      .artistName {
        color: white;
        font-weight: bolder;
      }

      strong {
        color: rgb(251, 70, 70);
        font-weight: bolder;
      }

      u {
        color: white;
        text-decoration: none;
      }

      i {
        color: rgb(242, 228, 217);
      }

      b {
        font-weight: bolder;
        text-decoration: underline;
      }

      a {
        color: #52ffff;
        &:hover {
          opacity: 0.7;
          transition: 0.3s;
        }
      }
    }
  }
}
